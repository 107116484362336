import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'context/IntlContext';
import { useColorMode } from 'theme-ui';
import { Select, Text, Flex, Box, Container, Heading } from 'components';
import { alpha } from '@theme-ui/color';
import Phone from '../../images/icons/phone.svg';
import Seo from '../../components/SEO';

const PAGE_COLOR_MODE = 'light';

const ContactUs: FC = () => {
  const { t, locale } = useIntl();
  const [tel, setTel] = useState('');
  const [colorMode, setColorMode] = useColorMode();

  const tels = [
    {
      phoneNumber: t('homepage.help.languages.global.number'),
      country: `🌐 ${t('homepage.help.languages.global')}`,
    },
    {
      phoneNumber: t('contact.phone_number.italy'),
      country: `🇮🇹 ${t('homepage.help.languages.italian')}`,
    },
    {
      phoneNumber: t('contact.phone_number.portugal'),
      country: `🇵🇹 ${t('homepage.help.languages.portuguese')}`,
    },
    {
      phoneNumber: t('contact.phone_number.spain'),
      country: `🇪🇸 ${t('homepage.help.languages.spanish')}`,
    },
  ];

  useEffect(() => {
    if (locale === 'es') {
      setTel(t('contact.phone_number.spain'));
    } else if (locale === 'it') {
      setTel(t('contact.phone_number.italy'));
    } else if (locale === 'pt') {
      setTel(t('contact.phone_number.portugal'));
    } else {
      setTel(t('homepage.help.languages.global.number'));
    }
  }, [locale]);

  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box sx={{ pt: 8, pb: [11, 11, 11, 0] }}>
        <Container>
          <Flex sx={{ flexDirection: 'column' }}>
            <Heading
              as="h1"
              sx={{
                variant: 'text.heroTitle',
                color: 'quaternary',
                mb: 7,
              }}
            >
              {t('homepage.help.title')}
            </Heading>

            <Box>
              <Text
                as="p"
                variant="underHero"
                sx={{
                  color: 'quaternary',
                  opacity: '0.8',
                  mb: 10,
                }}
              >
                {t('homepage.help.subtitle')}
              </Text>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'row'],
              }}
            >
              <Box
                sx={{
                  pt: 14,
                  maxWidth: ['100%', '100%', '100%', '748px'],
                  pr: 13,
                }}
              >
                <Text
                  variant="sectionTitle"
                  sx={{
                    color: 'quaternary',
                    mb: 7,
                  }}
                >
                  {t('homepage.help.hours.title')}
                </Text>
                <Text
                  as="p"
                  variant="underHero"
                  sx={{
                    color: 'quaternary',
                    opacity: '0.8',
                    mb: 10,
                  }}
                >
                  {t('homepage.help.hours.subtitle')}
                </Text>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: ['column', 'column', 'column', 'row'],
                  }}
                >
                  <Select
                    sx={{
                      '&, & + svg': {
                        display: 'block',
                        color: 'primary',
                        alignSelf: 'center',
                      },
                      borderRadius: 2,
                      py: 3,
                      borderColor: 'senary',
                      minWidth: '158px',
                      maxHeight: '48px',
                    }}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setTel(e.target.value)
                    }
                  >
                    {tels.map((i) => {
                      if (
                        (locale === 'en' && i.phoneNumber === t('homepage.help.languages.global.number')) ||
                        (locale === 'es' && i.phoneNumber === t('contact.phone_number.spain')) ||
                        (locale === 'it' && i.phoneNumber === t('contact.phone_number.italy')) ||
                        (locale === 'pt' && i.phoneNumber === t('contact.phone_number.portugal'))
                      ) {
                        return (
                          <option
                            key={i.country}
                            value={i.phoneNumber}
                            selected
                          >
                            {i.country}
                          </option>
                        );
                      }
                      return (
                        <option key={i.country} value={i.phoneNumber}>
                          {i.country}
                        </option>
                      );
                    })}
                  </Select>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ ml: '28px', mt: '31px' }}>
                      <Text
                        as="p"
                        variant="smallReferences"
                        sx={{
                          color: 'quaternary',
                          opacity: '0.8',
                        }}
                      >
                        {t('homepage.help.hours.phone_number')}
                      </Text>
                      <Text
                        as="p"
                        variant="smallReferences"
                        sx={{
                          color: 'quaternary',
                          mb: 10,
                          fontWeight: 'semibold',
                        }}
                      >
                        {tel}
                      </Text>
                    </Box>

                    <Box
                      sx={{
                        display: ['block', 'block', 'block', 'none'],
                        mt: '31px',
                      }}
                    >
                      <Phone />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  borderLeft: '1px solid',
                  borderColor: alpha('quaternary', 0.1),
                  paddingTop: 14,
                }}
              >
                <Text
                  variant="sectionTitle"
                  sx={{
                    color: 'quaternary',
                    mb: 7,
                    pl: [0, 0, 0, 7],
                  }}
                >
                  {t('homepage.help.email.title')}
                </Text>
                <Text
                  as="p"
                  variant="underContent"
                  sx={{
                    color: 'quaternary',
                    opacity: '0.8',
                    mb: 1,
                    pl: [0, 0, 0, 7],
                  }}
                >
                  {t('homepage.help.email.subtitle')}
                </Text>
                <Text
                  as="p"
                  variant="underContent"
                  sx={{
                    color: 'primary',
                    opacity: '0.8',
                    mb: 6,
                    fontWeight: 'semibold',
                    pl: [0, 0, 0, 7],
                  }}
                >
                  {t('homepage.help.email.email_address')}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default ContactUs;
